<template>
  <transition name="fade" v-if="loading">
    <loading-component></loading-component>
  </transition>

  <transition v-if="!loading" name="fade">
      <div class="bg-white" id="wrapper">
        <sidebar :page="'My Account'" :sidebar_data=sidebar_data></sidebar>
        
        <div class="backdrop"></div>
      
        <div>
          <nav class="navbar bg-transparent p-0 pb-2">
            <div class="container-fluid">
              <a class="navbar-brand bars d-flex me-2 mt-2" @click="this.$emit('update:screen', this.screen-1)">
                  <em class="ph-arrow-left text-secondary me-2"></em>
              </a>

              <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">                

              <notifications :user="user" :color_notifications_icons="'orange'"/>

              <div class="divider mt-4 d-none"></div>
            </div>
          </nav>

          <floating-nav :page="'Profile'"></floating-nav>

          <div class="container bg-white rounded" v-if="!screen_loading">
            <span id="profile-options" class="d-flex pt-2 pb-2" style="overflow-x: scroll;">
              <h5 :class="['content-title text-center ms-4', {'active': screen == 0}]" @click="changeScreen(0)">Basics</h5>

              <h5 :class="['content-title text-center ms-4', {'active': screen == 1}]" @click="changeScreen(1)">Personals</h5>

              <h5 :class="['content-title text-center ms-4', {'active': screen == 2}]" @click="changeScreen(2)">Security</h5>

              <h5 :class="['content-title text-center ms-4', {'active': screen == 3}]" @click="changeScreen(3)">Prescriptions</h5>
            </span>
            
            <status v-model:status_data=status_data></status>

            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div v-if=" !this.user.email_verified_at" class="alert fixed alert-dismissible alert-warning fade show" role="alert">
                      <h6 class="text-start fw-bold text-uppercase">  <font-awesome-icon class="icon me-2" :icon="['fa', 'info-circle']" /> Warning! </h6>

                      <p class="mb-0 pt-2">Please verify your account. You should have an email send to your inbox with instructions.</p>
                  </div>
                </div>
              </div> 
            </div>           

            <div class="row" v-if="screen == 0">
              <div class="col-lg-12 col-md-12">

                <ul class="list-group list-group-flush">
                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('name')" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'user']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Name</h6>
                        <p class="text-secondary mb-0">{{ user.name }}</p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-name mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="text" class="form-control" v-model="user.name" placeholder="Enter you Name.">
                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('name', 'users', user.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                    
                  </li>

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('email')" style="cursor: pointer">
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'envelope']" />
                      </div>

                      <div class="d-block">
                        <h6 class="fw-bold">Email</h6>
                        <p class="text-secondary mb-0">{{ user.email }}</p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-email mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="text" class="form-control" v-model="user.email" placeholder="Enter your Email.">
                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('email', 'users', user.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                     
                  </li>

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('phone_number')" style="cursor: pointer">
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'phone-alt']" />
                      </div>

                      <div class="d-block">
                        <h6 class="fw-bold">Phone Number</h6>
                        <p class="text-secondary mb-0">+ {{ user.phone_number }}</p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-phone_number mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="number" class="form-control" v-model="user.phone_number" placeholder="Enter your Phone Number.">
                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('phone_number', 'users', user.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>    

                    <small class="text-danger">
                      <p class="mb-0 mt-1" style="margin-left: 2.2rem;" v-for="(index) in errors.phone_number" :key="index"> &bull; {{ index }}</p> 
                    </small>                
                  </li>
                </ul>
              </div>
            </div>

            <div class="row" v-if="screen == 1 && user.kyc">
              <div class="col-lg-12 col-md-12">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('address')" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'location-arrow']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Address</h6>
                        <p v-if="user.kyc.address" class="text-secondary mb-0">{{ user.kyc.address }}</p>
                        <p v-if=" !user.kyc.address" class="text-secondary mb-0"> - </p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-address mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="text" class="form-control" v-model="user.kyc.address" placeholder="Enter your Address.">

                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('address', 'kyc', user.kyc.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                    
                  </li>

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('gender')" style="cursor: pointer">
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'venus-mars']" />
                      </div>

                      <div class="d-block">
                        <h6 class="fw-bold">Gender</h6>
                        <p v-if="user.kyc.gender" class="text-secondary mb-0 text-capitalize">{{ user.kyc.gender }}</p>
                        <p v-if=" !user.kyc.gender" class="text-secondary mb-0 text-capitalize"> - </p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>


                    <div class="d-none form-row edit-gender mt-2">
                      <div class="input-group" style="padding-left: 2rem;">

                        <select v-model="user.kyc.gender" class="form-select">
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>

                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('gender', 'kyc', user.kyc.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                       
                  </li>

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('marital')" style="cursor: pointer">
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'ring']" />
                      </div>

                      <div class="d-block">
                        <h6 class="fw-bold">Marital Status</h6>
                        <p v-if="user.kyc.marital" class="text-secondary mb-0 text-capitalize">{{ user.kyc.marital }}</p>
                        <p v-if=" !user.kyc.marital" class="text-secondary mb-0 text-capitalize"> - </p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-marital mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <select v-model="user.kyc.marital" class="form-select">
                          <option value="single">Single</option>
                          <option value="married">Married</option>
                          <option value="divorced">Divorced</option>
                        </select>

                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('marital', 'kyc', user.kyc.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                     
                  </li>

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('idno')" style="cursor: pointer">
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'id-card']" />
                      </div>

                      <div class="d-block">
                        <h6 class="fw-bold">National ID</h6>
                        <p v-if="user.kyc.idno" class="text-secondary mb-0">{{ user.kyc.idno }}</p>
                        <p v-if=" !user.kyc.idno" class="text-secondary mb-0 text-capitalize"> - </p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-info mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="number" class="form-control" v-model="user.kyc.idno" placeholder="Enter your ID Number.">

                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('idno', 'kyc', user.kyc.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                    
                  </li> 

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" @click="stateField('dob')" style="cursor: pointer">
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'clock']" />
                      </div>

                      <div class="d-block">
                        <h6 class="fw-bold">Date of Birth</h6>
                        <p v-if="user.kyc.dob" class="text-secondary mb-0">{{ user.kyc.dob }}</p>
                        <p v-if=" !user.kyc.idno" class="text-secondary mb-0 text-capitalize"> - </p>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>


                    <div class="d-none form-row edit-info mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="date" class="form-control" v-model="user.kyc.dob">

                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('idno', 'kyc', user.kyc.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div> 
                  </li>                                   
                </ul>
              </div>
            </div>

            <div class="row" v-if="screen == 1 && !user.kyc">
              <div class="col-lg-8 col-md-8 col-sm-8">
                <p class="ms-4" style="font-size: 1rem;">We have not found your personal details in the app. You can fill them out by clicking on the button below.</p> 

                <router-link :to="{name: 'Kyc', params: {user_id: 1}}" class="btn bg-orange text-white ms-4 mt-2">
                  Fill in <font-awesome-icon class="ms-2 me-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                </router-link>
              </div>
            </div>

            <div class="row" v-if="screen == 2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'user']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Enable 2-factor Authentication</h6>
                        
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="two_factor" :checked="user['two_factor'] == true" @click="changetwo_factor()">
                          <label v-if="user['two_factor'] == true" class="form-check-label" for="two_factor">Currently enabled</label>

                          <label v-if="user['two_factor'] == false" class="form-check-label" for="two_factor">Currently disabled</label>
                        </div>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>

                    <div class="d-none form-row edit-name mt-2">
                      <div class="input-group" style="padding-left: 2rem;">
                        <input type="text" class="form-control" v-model="user.name" placeholder="Enter you Name.">
                        <button class="btn btn-dark btn-sm" style="width: 10%" @click="editField('name', 'users', user.id)">
                          <font-awesome-icon :icon="['fa', 'angle-right']" />
                        </button>
                      </div>
                    </div>                    
                  </li>

                  <li class="list-group-item pt-4 pb-4">
                    <div class="d-flex" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4 text-danger" :icon="['fa', 'trash']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Account Status</h6>
                        <p class="text-secondary mb-0">Please note that this action is permanent.</p>

                        <router-link :to="{name: 'DeleteUser'}" class="btn btn-danger btn-sm mt-3">
                          <span class="ms-2 me-2">Delete Account</span>
                        </router-link>
                      </div>

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'trash']" />
                      </div>
                    </div>                 
                  </li>                  
                </ul>
              </div>
            </div>

            <div class="row" v-if="screen == 3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item pt-4 pb-4" v-for="({hospital, doctor, doctor_licence_number}, index) in prescriptions" :key="index">
                    <p>{{ index }}. </p>                    
                    <div class="d-flex" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'user']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Hospital</h6>
                        <p class="text-secondary mb-0">{{ hospital }}</p>
                      </div>                                                            

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div> 

                    <div class="d-flex mt-4" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'user']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Doctor</h6>
                        <p class="text-secondary mb-0">{{ doctor }}</p>
                      </div>                                                            

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div> 

                    <div class="d-flex mt-4" style="cursor: pointer">  
                      <div class="d-flex" style="align-items: center">
                        <font-awesome-icon class="me-4" :icon="['fa', 'user']" />
                      </div>
                      
                      <div class="d-block">
                        <h6 class="fw-bold">Doctor Licence No.</h6>
                        <p class="text-secondary mb-0">{{ doctor_licence_number }}</p>
                      </div>                                                            

                      <div class="ms-auto d-flex">
                        <font-awesome-icon class='t-darker mt-auto mb-auto' :icon="['fa', 'user-edit']" />
                      </div>
                    </div>                                          
                                     
                  </li>
                </ul>              
            </div>
          </div>

          <div class="container-fluid bg-white m-0 pb-4" v-if="screen_loading" style="position: relative; top: 1rem;">
            <div class="row mb-4">
                <div class="d-flex mb-2 mt-4">
                    <h3 class="spinner-border spinner-border-sm fw-bold text-warning mx-auto mt-2" role="status">
                      <span class="sr-only">Loading...</span>
                    </h3> 
                </div>

                <p class="fw-bold text-center text-warning" style="letter-spacing: -0.2px">Give it a sec...</p>             
            </div>
          </div>
        </div>
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/helpers/_breakpoints.scss';
  @import '../assets/scss/layout/_form.scss';
  @import '../assets/scss/abstracts/colors.scss';
  @import '../assets/scss/layout/_navbar.scss';
  
  .body {
    padding-left: 280px;
  }

  .content-title {
    margin-top: 4px;
    cursor: pointer;
  }

  .active { 
    margin-top: 0 !important;
    background: $orange-color;
    padding: 0.4rem 1rem;
    color: white;
    border-radius: 1rem;
    font-size: 1.1rem;
    letter-spacing: .6px;
  }


  .nav-bottom {
    border-top-left-radius: 80%; 
    padding: 0rem 2rem 0.5rem 2rem;
    border-top-right-radius: 80%;
    z-index: 1;

    .nav-item {
      cursor: pointer;
    }
  }

  #profile-options::-webkit-scrollbar
  {
    display: none;
  }

</style>

<script>  
  import Sidebar from '../components/AutoSidebar'
  import Status from '../components/Status'
  import LoadingComponent from '../components/Loading'
  import Notifications from '../components/Notifications'
  import FloatingNav from '../components/FloatingNav'

  export default {
    name: 'Admin',
    components: {
      Sidebar,
      Status,
      LoadingComponent,
      Notifications,
      FloatingNav
    },
    data() {
      return {
        color: '',
        show_divider: '',
        screen_loading: false,
        success: false,
        error: false,
        error_message: '',
        success_message: '',
        field: '',
        data: {
          name: 'Fredrick',
          email: 'fredrickmakoffu@gmail.com',
          phone_number: '0711787441'
        },     
        sidebar_data: [
          {title: 'Home', link: '/', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
        ],
        status_data: {
          "success": false,
          "success_message": null,
          "error": false,
          "error_message": null,
          "loading": false
        },
        errors: {},        
        screen: 0,
        prescriptions: []  
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.getPrescriptions()
    },
    methods: {
      getPrescriptions() {
        this.axios.get('api/prescription/patient').then(response => {
          this.prescriptions = response.data.data
        })
      },
      changeScreen(value) {
        this.screen = value
        this.field = null
      },
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      },
      stateField(value) {
        if(this.field) {
          document.querySelector('.edit-' + this.field).classList.add('d-none')
        }

        this.field = value
        
        document.querySelector('.edit-' + this.field).classList.remove('d-none')
      },
      capitalize(myStr) {
        return myStr.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
      },  
      editField(value, resource, id) {
        let data = {}
        if(resource == 'users') {
          data[this.field] = this.user[value]
        } else if(resource == 'kyc') {
          data[this.field] = this.user.kyc[value]
        }

        this.axios.put('/api/' + resource + '/' + id, data).then(() => {
          this.status_data.loading = false
          this.status_data.success = true
          this.status_data.error = false
          this.status_data.success_message = 'Updated!'

          setTimeout(() => {
            this.status_data.success = false
          }, 1500);
        }).catch(error => {
          this.status_data.loading = false
          this.status_data.success = false
          this.status_data.error = true
          this.errors = error.response.data.data
          console.log(error);
        })

      },
      changetwo_factor() {
        let two_factor = null

        if(this.user['two_factor'] == true) {
          two_factor = "false"
        } else {
          two_factor = "true"
        }

        let data = {
          'two_factor': two_factor
        }

        alert(two_factor)

        this.axios.put('/api/users', data).then((response) => {
          this.status_data.loading = false
          this.status_data.success = true
          this.status_data.error = false
          this.status_data.success_message = 'Updated!'

          if(this.user['two_factor'] == true) {
            this.user['two_factor'] = false
          } else {
            this.user['two_factor'] = true
          }

          console.log(response);

          setTimeout(() => {
            this.status_data.success = false
          }, 1500);
        }).catch(error => {
          this.status_data.loading = false
          this.status_data.success = false
          this.status_data.error = true
          this.errors = error.response.data.data
          console.log(error);
        })
      },
      routeTo(value) {
        this.$router.push({name: value})
      }
    }
  }
  
</script>
